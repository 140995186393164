<template>
  <div class="garmentPO-Detail">
    <div class="page-info">
      <div class="page-title">
        <v-icon color="secondary">mdi-clipboard-outline</v-icon>
        <p class="title-1">Garment PO Detail</p>
      </div>

      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-card outlined flat rounded="xl" class="pa-8">
      <v-data-table
        hide-default-footer
        disable-sort
        :headers="headers"
        :items="items"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :no-data-text="$t('common.text.noData')"
        :no-results-text="$t('common.text.noMatchingResult')">
        <template v-slot:[`item.sysRegDt`]="{ item }">
          {{ $m(item.sysRegDt).format("yyyy-MM-DD") }}
        </template>
      </v-data-table>

      <div class="text-center pt-2" v-if="pageCount > 1">
        <v-pagination v-model="page" :length="pageCount" :total-visible="$pagePV"></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import { hasValue } from "@/utils/util";

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Purchase Order",
          disabled: true,
        },
        {
          text: "Garment PO List",
          exact: true,
          to: { name: "garmentPO" },
        },
        {
          text: "Garment PO Detail",
          disabled: true,
        },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: "po_UUID", value: "po_personal_UUID", class: "vertical-base" },
        { text: "StyleNo", value: "styleNo", class: "vertical-base" },
        { text: "QuoteNo", value: "quoteNo", class: "vertical-base" },
        { text: "ItemNo", value: "itemNo", class: "vertical-base" },
        { text: "POType", value: "poType", class: "vertical-base" },
        { text: "PO", value: "po", class: "vertical-base" },
        { text: "DCPO", value: "dcPo", class: "vertical-base" },
        { text: "OrderType", value: "orderType", class: "vertical-base" },
        { text: "DivisionNo", value: "divisionNo", class: "vertical-base" },
        { text: "DeptNo", value: "deptNo", class: "vertical-base" },
        { text: "Incoterms", value: "incoterms", class: "vertical-base" },
        { text: "CO", value: "co", class: "vertical-base" },
        { text: "Season", value: "season", class: "vertical-base" },
        { text: "Destination", value: "destination", class: "vertical-base" },
        { text: "ShipMode", value: "shipMode", class: "vertical-base" },
        { text: "ShipDate", value: "shipDate", class: "vertical-base" },
        { text: "CancelDate", value: "cancelDate", class: "vertical-base" },
        {
          text: "WarehouseShipDate",
          value: "whseShipDate",
          class: "vertical-base",
        },
        {
          text: "WarehouseCancelDate",
          value: "whseCancelDate",
          class: "vertical-base",
        },
        {
          text: "InStorageDATE",
          value: "inStorageDate",
          class: "vertical-base",
        },
        { text: "InStoreDate", value: "inStoreDate", class: "vertical-base" },
        { text: "Colorname", value: "colorname", class: "vertical-base" },
        { text: "Size", value: "poSize", class: "vertical-base" },
        { text: "FOBPrice", value: "fobprice", class: "vertical-base" },
        { text: "RetailPrice", value: "retailPrice", class: "vertical-base" },
        { text: "NetFirstCost", value: "netFirstCost", class: "vertical-base" },
        { text: "StoreCost", value: "storeCost", class: "vertical-base" },
        {
          text: "VendorPackCantonQty",
          value: "vendorPackCartonQty",
          class: "vertical-base",
        },
        {
          text: "WarehousePackCartonQty",
          value: "warehousePackCartonQty",
          class: "vertical-base",
        },
        {
          text: "TotalCartonsPerLine",
          value: "totalCartonsPerLine",
          class: "vertical-base",
        },
        { text: "AllotedQty", value: "allotedQty", class: "vertical-base" },
        { text: "RatioQty", value: "ratioQty", class: "vertical-base" },
        { text: "Amount", value: "amount", class: "vertical-base" },
        { text: "Factoryname", value: "factoryname", class: "vertical-base" },
        { text: "Consolidator", value: "consolidator", class: "vertical-base" },
        {
          text: "ItemDescription",
          value: "itemDescription",
          class: "vertical-base",
        },
        { text: "HTS", value: "hts", class: "vertical-base" },
        { text: "CAT", value: "cat", class: "vertical-base" },
        {
          text: "FacbricContents",
          value: "facbricContents",
          class: "vertical-base",
        },
        { text: "orderCompany", value: "orderCompany" },
        { text: "sysRegDt", value: "sysRegDt", class: "vertical-base" },
      ],
      items: [],
    };
  },
  created() {
    const { cmpyUUID, garmentPOUUID } = this.$store.state.currentRoute.params;
    if (![cmpyUUID, garmentPOUUID].every(hasValue)) return;

    this.$http
      .get("/garmentPo/select", {
        data: {
          cmpyUUID,
          garmentPOUUID,
        },
      })
      .then(res => {
        if (res.data.status === 200) this.items = res.data.data;
        else codeHandler(res.data);
      });
  },
};
</script>

<style lang="scss">
.garmentPO-Detail {
  th {
    color: black !important;
    vertical-align: inherit !important;
  }
}
</style>
